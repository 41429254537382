import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/AtlasPicture/Details.aspx',
    name: 'AtlasPictureDetails',
    component: () => import('../views/AtlasPicture/Details.aspx')
  },
  {
    path: '/Confirmation/Details.aspx',
    name: 'confirmationDetails',
    component: () => import('../views/Confirmation/Details.aspx')
  },
  {
    path: '/DiagnosticExamination/Details.aspx',
    name: 'diagnosticExaminationDisease',
    component: () => import('../views/DiagnosticExamination/Details.aspx')
  },
  {
    path: '/Disease/Details.aspx',
    name: 'diseaseDisease',
    component: () => import('../views/Disease/Details.aspx')
  },
  {
    path: '/DiseaseResearch/Details.aspx',
    name: 'diseaseResearchDetails',
    component: () => import('../views/DiseaseResearch/Details.aspx')
  },
  {
    path: '/Medicine/Details.aspx',
    name: 'medicineDisease',
    component: () => import('../views/Medicine/Details.aspx')
  },
  {
    path: '/OperatingDiscipline/Details.aspx',
    name: 'operatingDisciplineDetails',
    component: () => import('../views/OperatingDiscipline/Details.aspx')
  },
  {
    path: '/Operation/Details.aspx',
    name: 'operationDisease',
    component: () => import('../views/Operation/Details.aspx')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
